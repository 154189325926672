<template>
  <div>
    <h5>
      Borders
    </h5>
    <table
      v-if="borderArea.length"
      :class="['table kendo-table table-kendo mb-2', { 'is-invalid': $validator.errors.has('BorderArea') }]"
    >
      <colgroup>
        <col width="35">
      </colgroup>
      <thead>
        <tr>
          <th />
          <th>{{ $t('pan') }}</th>
          <th>{{ $t('tilt') }}</th>
          <th>{{ $t('options') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in borderArea"
          :key="`table-row-${ index }`"
        >
          <td>{{ index }}.</td>
          <td>
            <input
              v-model.number="item.pan"
              class="form-control form-control-sm"
              type="number"
              min="-170"
              max="170"
              step="1"
              @keyup="item.pan = clamp(item.pan.toFixed(3), -170, 170)"
              @click="changePtz({ pan: item.pan, tilt: item.tilt })"
            >
          </td>
          <td>
            <input
              v-model.number="item.tilt"
              class="form-control form-control-sm"
              type="number"
              min="-80"
              max="80"
              step="1"
              @keyup="item.tilt = clamp(item.tilt.toFixed(3), -80, 80)"
              @click="changePtz({ pan: item.pan, tilt: item.tilt })"
            >
          </td>
          <td>
            <button
              class="btn btn-sm btn-secondary btn-block"
              @click="removeItem(index)"
            >
              <font-awesome-icon
                class="mr-2 gray"
                icon="trash"
              />
              <span>{{ $t('remove') }}</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <hr v-else>
    <span
      v-show="errors.has('BorderArea')"
      class="badge badge-danger"
    >{{ errors.first('BorderArea') }}</span>
    <button
      class="btn btn-sm btn-primary float-right"
      @click="addItem()"
    >
      <font-awesome-icon
        class="mr-2"
        icon="plus"
      />
      <span>{{ $t('add') }}</span>
    </button>
    <div class="clearfix" />
  </div>
</template>

<script>
export default {
  name: 'BorderConfiguration',
  props: {
    borderArea: {
      type: Array,
      required: true
    },
  },
  methods: {
    // HELPER METHODS START
    clamp (num, min, max) {
      return num <= min ? min : num >= max ? max : num;
    },
    addDefaultBorderArea () {
      this.borderArea.push({ pan: -80, tilt: 30 });
      this.borderArea.push({ pan: 80, tilt: 30 });
      this.borderArea.push({ pan: 80, tilt: -30 });
      this.borderArea.push({ pan: -80, tilt: -30 });
      this.update();
    },
    removeItem (removeIndex) {
      this.borderArea.splice(removeIndex, 1);
      this.update();
    },
    addItem () {
      if (this.borderArea.length === 0) {
        this.addDefaultBorderArea();
        return;
      }

      let pan = 0;
      let tilt = 0;

      if (this.borderArea.length >= 2) {
        let firstPosition = this.borderArea[0];
        let lastPosition = this.borderArea[this.borderArea.length - 1];
        pan = (firstPosition.pan + lastPosition.pan) / 2;
        tilt = (firstPosition.tilt + lastPosition.tilt) / 2;
      }

      this.borderArea.push({ pan: pan, tilt: tilt });
      this.update();
    },
    update () {
      this.$emit('update');
    },
    changePtz (position) {
      this.$emit('changePtz', position);
    }
  }
}
</script>